import {useEffect, useRef, useCallback} from 'react';
import type {AnimationConfig as LottieAnimationConfig} from 'lottie-web';

import {useMediaQuery} from '@/hooks/useMediaQuery';

import useLottieWeb from './useLottieWeb';

type AnimationConfig = Omit<LottieAnimationConfig, 'container'>;

type Options = {
  animateOnHover?: boolean;
  lastFrameOnReducedMotion?: boolean;
};
const defaultOptions = {
  animateOnHover: false,
  lastFrameOnReducedMotion: false,
};

export default function useLottieAnimation(
  animationData: string | Record<string, any> | undefined,
  animationConfig: AnimationConfig = {},
  options: Options = {},
) {
  const lottie = useLottieWeb();
  const element = useRef<HTMLDivElement>(null);
  const currentElement = element.current;
  const prefersReducedMotion = useMediaQuery(
    '(prefers-reduced-motion: reduce)',
  );

  animationConfig.autoplay = !prefersReducedMotion;

  const {animateOnHover, lastFrameOnReducedMotion} = {
    ...defaultOptions,
    ...options,
  };

  const getPathOrAnimationData = useCallback(() => {
    if (typeof animationData === 'string') return {path: animationData};
    return {animationData};
  }, [animationData]);

  useEffect(() => {
    if (!lottie) return;
    const instance = lottie.loadAnimation({
      ...getPathOrAnimationData(),
      ...animationConfig,
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      container: element?.current!,
    });

    prefersReducedMotion &&
      lastFrameOnReducedMotion &&
      instance.goToAndStop(instance.getDuration(true), true);

    const handleMouseEnter = () => instance.play();
    const handleMouseLeave = () => instance.stop();

    if (animateOnHover && !prefersReducedMotion) {
      currentElement?.addEventListener('mouseenter', handleMouseEnter);
      currentElement?.addEventListener('mouseleave', handleMouseLeave);
    }
    if (prefersReducedMotion || (window as any).REDUCE_MOTION) {
      instance.pause();
    }
    return () => {
      if (animateOnHover && !prefersReducedMotion) {
        currentElement?.removeEventListener('mouseenter', handleMouseEnter);
        currentElement?.removeEventListener('mouseleave', handleMouseLeave);
      }
      instance.destroy();
    };
  }, [
    animationData,
    animationConfig,
    lottie,
    prefersReducedMotion,
    currentElement,
    animateOnHover,
    lastFrameOnReducedMotion,
    getPathOrAnimationData,
  ]);

  return element;
}
